import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Link } from '@tanstack/router'
import { useTranslation } from 'react-i18next'

import useDimensions from '../../hooks/useDimensions'
import type { AnnouncementObj } from '../../types/announcement'
import closeModal from '../../utils/closeModal'
import AllAnnouncementsModal from './all-announcements-modal'
import Modal from './modal'

type AnnouncementModalProps = {
  announcement: AnnouncementObj
}

const AnnouncementModal = ({ announcement }: AnnouncementModalProps) => {
  const modal = useModal()
  const allAnnouncementsModal = useModal(AllAnnouncementsModal)
  const { t } = useTranslation('announcementModal')
  const { isDesktop } = useDimensions()

  return (
    <Modal id="announcement-modal">
      <div style={{ padding: '0 20px' }}>
        <p className="text-2xl font-bold text-txt text-center break-words mb-5">
          {t('announcement')}
        </p>
        <p className="text-base font-normal text-txt text-center break-words mb-[5px] xl:text-lg">
          {announcement?.content}
        </p>
      </div>
      <div className="flx-ctr" style={{ marginTop: '45px', gap: '15px' }}>
        <button
          className="secondary w-[120px] text-center text-lg text-white transition-all duration-[0.2s] ease-[ease-in-out] cursor-pointer px-4 py-[7px] rounded-[22px] xl:text-xl xl:w-[150px] xl:px-0 xl:py-2.5 xl:rounded-[26px] bg-[#65676a] border-[#65676a]"
          onClick={() => closeModal(modal)}>
          {t('cancel')}
        </button>
        <Link to={isDesktop ? undefined : '/announcements'}>
          <span
            className="w-[120px] text-center text-lg text-white transition-all duration-[0.2s] ease-[ease-in-out] cursor-pointer px-0 py-[7px] rounded-[22px] xl:text-xl xl:w-[150px] xl:px-0 xl:py-2.5 xl:rounded-[26px] bg-[#46aef7]"
            onClick={() => {
              closeModal(modal)
              isDesktop && allAnnouncementsModal.show()
            }}>
            {t('view-all')}
          </span>
        </Link>
      </div>
    </Modal>
  )
}

export default NiceModal.create(AnnouncementModal)
